<template>
  <div style="display: inline;margin-right: 10px">
    <el-link :underline="true" type="primary" @click="jumpToDetail" v-if="canEdit" title="点击查看【红人详情】" v-bind="$attrs" v-on="$listeners">{{ info.nickname }}
    </el-link>
    <el-tooltip class="item" effect="dark" content="无权限：查看详情" placement="top-start" v-else>
      <el-link :underline="false" type="info">{{ info.nickname }}</el-link>
    </el-tooltip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ArtistNickname",
  computed: {
    ...mapGetters(['userPermissions', 'needTagsView'])
  },
  props: {
    info: {
      type: Object,
      default() {
        return {
          artist_id: '',
          nickname: '--'
        }
      }
    },
    canEdit: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  methods: {
    jumpToDetail() {
      if (this.needTagsView) {
        this.$router.push({
          name: 'artist_intro',
          params: { artist_id: this.info.artist_id }
        })
      } else {
        const { href } = this.$router.resolve({
          name: 'artist_intro',
          params: { artist_id: this.info.artist_id }
        })
        window.open(href, '_blank')
      }

    },
  }

}
</script>

<style scoped>

</style>
