<template>
  <div>
    <div class="breadcrumb">
      <i class="iconfont header-index-trigger" :class="[isCollapse?'icon-menu-fold':'icon-menu-unfold']"
         @click="handleClick"></i>
      <el-breadcrumb separator="/" style="margin-left: 10px;font-size: 1rem;font-weight: 800;">
        <transition-group name="breadcrumb">
          <el-breadcrumb-item
              v-for="(item) in crumbList"
              :key="item.path">
            <router-link :to="item.redirect||item.path||'/'"><span>{{ item.meta.title }}</span></router-link>
          </el-breadcrumb-item>
        </transition-group>
      </el-breadcrumb>
      <div class="system-note">
        <ApeNoticeBar v-if="userPermissions.indexOf('necessary_notice')>-1" :text="noticeDefault" :background="`#fff`"/>
      </div>
      <span style="color: #ff3176;cursor: pointer" v-if="showInnerMenu" @click="$router.go(-1)"><i
          class="el-icon-back"></i> 返回</span>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import ApeNoticeBar from '@/components/ApeNoticeBar'

export default {
  components: {
    ApeNoticeBar
  },
  computed: {
    ...mapGetters(['isCollapse', 'userPermissions']),
    showInnerMenu() {
      return this.$route.meta.hideInnerMenu === undefined ? false : true
    }
  },
  data() {
    return {
      crumbList: null,
      noticeDefault: '暂无系统消息'
    }
  },
  methods: {
    getBreadcrumb() {
      let matched = this.$route.matched.filter(item => item.meta.title)
      this.crumbList = matched
    },
    // 左侧菜单的展开折叠
    handleClick() {
      this.$store.commit('changeCollapse')
    },
  },
  created: function () {
    this.getBreadcrumb()
    //使用上次的状态，即同上次关闭页面时候的状态
    this.$store.commit('showLastCollapse')
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  }
}
</script>
<style scoped>
.el-breadcrumb {
  margin-bottom: 10px
}

.breadcrumb {
  display: flex
}

.header-index-trigger {
  font-size: 1rem;
  cursor: pointer;
  transition: all .1s, padding 1s;
  line-height: 16px;
}

.system-note {
  margin-left: 10px;
  flex: 10;
}

</style>
