<template>
  <div>
    <div class="box" :style="`background-color: ${background}`">
      <div :class="`${scrollable?'animate click':'click'}`" :style="`color:${color}`" @click="getLastNotice">
        {{ noticeContent || text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApeNoticeBar",//消息通知滚动条
  props: {
    text: {
      type: String,
      default() {
        return ''
      }
    },
    color: {
      type: String,
      default() {
        return '#f60'
      }
    },
    background: {
      type: String,
      default() {
        return '#fff7cc'
      }
    },
    scrollable: {
      type: Boolean,
      default() {
        return true;
      }
    },
    LeftIcon: {
      type: String,
      default() {
        return 'el-icon-service'
      }
    }
  },
  data() {
    return {
      noticeContent: ''
    }
  },
  methods: {
    async getLastNotice() {
      this.noticeContent = ''
      //获取最新系统消息
      let {info} = await this.$api.lastNotice()
      if (info) {
        this.noticeContent = info.notice_content || '暂无系统消息'
      }
    }
  },
  created() {
    this.getLastNotice()
  }
}
</script>

<style scoped>
.box {
  width: 100%;
  margin: 0 auto;
  /*border: 1px solid #ff6700;*/
  overflow: hidden;
}

.click {
  cursor: pointer;
}

.click:hover {
  font-size: 1.2em;
}

.animate {
  margin-left: 10px;
  padding-left: 20px;
  font-size: 1em;
  /*color: #000;*/
  display: inline-block;
  /*white-space: nowrap;*/
  animation: 10s wordsLoop linear infinite normal;
}

@keyframes wordsLoop {
  0% {
    transform: translateX(200px);
    -webkit-transform: translateX(200px);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}

@-webkit-keyframes wordsLoop {
  0% {
    transform: translateX(200px);
    -webkit-transform: translateX(200px);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}
</style>
