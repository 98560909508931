<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-row class="table-header">
          <el-form v-model="searchCondition" :inline="true">
            <el-form-item label="发版状态：">
              <el-select v-model="searchCondition.release_status" @change="search" clearable>
                <el-option label="已发布" value="Y">已发布</el-option>
                <el-option label="未发布" value="N">未发布</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="红人检索：">
              <artist-search @handleSelect="chooseArtist" size="small"/>
            </el-form-item>
            <el-form-item label="年份：">
              <el-date-picker
                  v-model="searchCondition.year"
                  type="year"
                  format="yyyy年"
                  value-format="yyyy"
                  placeholder="选择年" @change="search" clearable>
              </el-date-picker>
            </el-form-item>
            <el-form-item label="月份：">
              <el-date-picker
                  v-model="searchCondition.month"
                  type="month"
                  format="MM月"
                  value-format="MM"
                  placeholder="选择月" @change="search" clearable>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search">搜索</el-button>
            </el-form-item>
          </el-form>
        </el-row>
        <br/>
        <!--   table列表     -->
        <div class="default-table">
          <ApeTable ref="apeTable"
                    :data="dataList"
                    :columns="columns"
                    :loading="loadingStatus"
                    :pagingData="pagingData"
                    @switchPaging="switchPaging"
                    @changeTableSort="changeTableSort"
                    highlight-current-row
                    border>
            <el-table-column
                slot="second-column"
                width="64"
                label="序号" align="center" fixed="left">
              <template slot-scope="scope">
                <span>{{ offset + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column slot="second-column" prop="nickname" label="红人昵称" min-width="120" align="center"
                             show-overflow-tooltip sortable="custom">
              <template slot-scope="scope">
                <artist-nickname :info="scope.row"/>
              </template>
            </el-table-column>

          </ApeTable>
        </div>

      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageHeaderLayout from "@/layouts/PageHeaderLayout";
import ApeTable from "@/components/ApeTable";
import ArtistSearch from "@/components/artist/ArtistSearch";
import ArtistNickname from "@/pages/artist/ArtistNickname";

export default {
  name: "PerformanceTrendList",
  components: {
    PageHeaderLayout,
    ApeTable,
    ArtistNickname,
    ArtistSearch
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  data() {
    return {
      loadingStatus: false,
      // 表格列表数据
      dataList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 15,
        // offset: 0,
      },
      // 分页的offset,序号列使用
      offset: 0,
      columns: [
        {
          title: '数据ID',
          value: 'id',
          width: 80,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        // {
        //   title: '红人昵称',
        //   value: 'nickname',
        //   width: 120,
        //   header_align: 'center',
        //   align: 'center',
        //   sortable: true
        // },
        {
          title: '分组',
          value: 'group_name',
          width: 100,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        {
          title: '平台',
          value: 'platform_name',
          width: 120,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        {
          title: '年份',
          value: 'year',
          width: 80,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        {
          title: '月份',
          value: 'month',
          width: 80,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        }, {
          title: '季度',
          value: 'quarter',
          width: 80,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        {
          title: '系数',
          value: 'quotient',
          width: 80,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        {
          title: '目标值',
          value: 'flag_value',
          width: 80,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        {
          title: '完成值',
          value: 'real_value',
          width: 80,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        }, {
          title: '是否发布',
          value: 'release_status',
          width: 80,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 80,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        }, {
          title: '修改',
          value: 'updated_at',
          width: 80,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
      ],
      searchCondition: { release_status: 'Y', artist_id: null },
      orderSort: { id: 'desc' }//默认排序规则
    }
  },
  methods: {
    chooseArtist(item) {
      if (item) {
        this.searchCondition.artist_id = item.id
      } else {
        this.searchCondition.artist_id = null
      }
      this.search()
    },
    search() {
      this.$refs['apeTable'].handleCurrentChange(1)
    },
    // 切换页码操作
    async switchPaging() {
      await this.initList()
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.search()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.release_status) {
        condition.release_status = this.searchCondition.release_status
      }
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year * 1
      }
      if (this.searchCondition.month) {
        condition.month = this.searchCondition.month * 1
      }

      return condition
    },
    // 初始化列表
    async initList(type) {
      this.loadingStatus = true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      // Object.assign(searchCondition, pagingInfo)
      Object.assign(searchCondition, pagingInfo, { order: this.orderSort })
      this.dataList = []
      let { list, pages } = await this.$api.getPerformanceTrendList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.pagingData.total = pages.total
      this.offset = pages.offset
      this.loadingStatus = false
    },
  },
  mounted() {
    this.initList()
  },
}
</script>

<style scoped>

</style>
