<template>
  <div>
    <el-autocomplete
      :fetch-suggestions='querySearchAsync'
      @clear='handleSelect'
      @select='handleSelect'
      clearable
      placeholder='红人检索：输入关键字'
      v-bind='$attrs'
      v-model='userName'
      v-on='$listeners'
    >
      <!--            <i-->
      <!--                    @click="clickBtn"-->
      <!--                    class="el-icon-search"-->
      <!--                    slot="prefix">-->
      <!--            </i>-->
    </el-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'ArtistSearch',
  props: {
    name: {
      type: String,
      default() {
        return ''
      }
    },
    size: {
      type: String,
      default() {
        return 'mini'
      }
    },
    clear: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      userName: this.name
    }
  },
  watch: {
    name: function(newVal, oldVal) {
      if (newVal !== oldVal)
        this.userName = newVal
    },
    userName: function(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$emit('update:name', newVal)
    },
    clear(val) {
      if (val) {
        this.userName = ''
      }
    }
  },
  methods: {
    async querySearchAsync(queryString, cb) {
      if (this.userName) {
        let { list } = await this.$api.searchArtists({ nickname: this.userName })
        //数据处理
        list = list.map((item) => {
          item.value = item.nickname
          return item
        })
        cb(list)
      } else {
        cb([])
      }
    },
    // 处理搜索结果的选中
    handleSelect(item) {
      if (item) {
        this.$emit('update:artist-id', item ? item.id : '')
        this.$emit('update:artist-name', item ? item.nickname : '')
        this.$emit('handleSelect', item)
      } else {
        this.$emit('handleSelect', null)
      }

    },
    clearSelect: function() {
      this.name = ''
      this.$emit('handleSelect', {})
    },
    clickBtn() {
      this.$emit('clicked', this.userName)
    }
  }

}
</script>

<style scoped>

</style>
